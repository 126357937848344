<template>
    <div class="main">
        <div class="search">
            <span style="font-weight: bold;font-size: 14px;color: #fff9ff">팝업설정</span>
        </div>
        <div class="data">
            <p style="color: red;width: 100%">
                * Login팝업은 최대 2개만 적용됩니다.
            </p>
            <el-table
                    :data="popupList"
                    style="width: 100%"
                    max-height="750"
                    border>
                <el-table-column
                        fixed
                        label="번호"
                        width="100">
                    <template slot-scope="scope">
                        {{scope.row.id}}
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="팝업명"
                        width="200">
                    <template slot-scope="scope">
                        <span>{{scope.row.title}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="위치"
                        width="100">
                    <template slot-scope="scope">
                        <span class="text-blue" v-if="scope.row.platform == managerConst.Popup.PLATFORM_MAIN">Main팝업</span>
                        <span class="text-red" v-if="scope.row.platform == managerConst.Popup.PLATFORM_LOGIN">Login팝업</span>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="상태"
                        width="100">
                    <template slot-scope="scope">
                        <span class="text-blue" v-if="scope.row.display == managerConst.ENABLE">사용중</span>
                        <span class="text-red" v-if="scope.row.display == managerConst.DISABLE">x</span>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="링크"
                        width="260">
                    <template slot-scope="scope">
                        {{scope.row.httplink}}
                    </template>
                </el-table-column>

                <el-table-column
                        fixed
                        label=""
                        width="250">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" @click="edit(scope.row)">수정</el-button>
                        <el-button size="mini" type="warning" @click="copyPopup(scope.row.id)">복사</el-button>
                        <el-button size="mini" type="danger" @click="deletePopup(scope.row.id)">삭제</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-dialog title="팝업수정" :visible.sync="visiable" style="width: 100%">
                <div style="display: flex;align-items: center;justify-content: space-between;flex-wrap: wrap">
                    <p style="color: blue">팝업이미지 추천 사이즈: 넓이 375px * 높이470px </p>

                    <div style="width: 100%">
                        <h5>팝업명</h5>
                        <el-input size="mini" style="width: 80%" placeholder="팝업명" v-model="popup.title"></el-input>
                    </div>
                    <div style="width: 33%">
                        <h5>팝업유형</h5>
                        <el-select size="mini" style="width: 80%" v-model="popup.type" placeholder="팝업유형">
                            <el-option :value="managerConst.Popup.TYPE_IMAGE" label="이미지">이미지</el-option>
                            <el-option :value="managerConst.Popup.TYPE_CONTENT" label="문구">문구</el-option>
                        </el-select>
                    </div>

                    <div style="width: 33%">
                        <h5>상태</h5>
                        <el-select size="mini" style="width: 80%" v-model="popup.display" placeholder="상태">
                            <el-option :value="managerConst.ENABLE" label="사용">사용</el-option>
                            <el-option :value="managerConst.DISABLE" label="숨김">숨김</el-option>
                        </el-select>
                    </div>
                    <div style="width: 33%">
                        <h5>플랫폼</h5>
                        <el-select size="mini" style="width: 80%" v-model="popup.platform" placeholder="위치">
                            <el-option :value="managerConst.Popup.PLATFORM_MAIN" label="Main팝업">Main팝업</el-option>
                            <el-option :value="managerConst.Popup.PLATFORM_LOGIN" label="Login팝업">Login 팝업</el-option>
                        </el-select>
                    </div>
                    <div style="width: 33%">
                        <h5>연결주소</h5>
                        <el-input type="" size="mini" v-model="popup.httplink"></el-input>
                    </div>


<!--                    <div style="width: 33%">-->
<!--                        <h5>넓이</h5>-->
<!--                        <el-input size="mini" style="width: 80%" placeholder="넓이" v-model="popup.width"></el-input>-->
<!--                    </div>-->
<!--                    <div style="width: 33%">-->
<!--                        <h5>높이</h5>-->
<!--                        <el-input size="mini" style="width: 80%" placeholder="높이" v-model="popup.height"></el-input>-->
<!--                    </div>-->
<!--                    <div style="width: 33%">-->
<!--                        <h5>z-index</h5>-->
<!--                        <el-input size="mini" style="width: 80%" placeholder="z-index"-->
<!--                                  v-model="popup.zindex"></el-input>-->
<!--                    </div>-->
<!--                    <div style="width: 33%">-->
<!--                        <h5>Top(px)</h5>-->
<!--                        <el-input size="mini" style="width: 80%" placeholder="Top" v-model="popup.top"></el-input>-->
<!--                    </div>-->
<!--                    <div style="width: 33%">-->
<!--                        <h5>Left(%)</h5>-->
<!--                        <el-input size="mini" style="width: 80%" placeholder="Left" v-model="popup.left"></el-input>-->
<!--                    </div>-->
                    <div style="width: 33%">
                        <h5>이미지 등록</h5>
                        <el-upload
                                ref="upload"
                                class="upload-demo"
                                action="#"
                                :http-request="httpRequest"
                                :before-upload="beforeUpload"
                                :on-change="fileChanged"
                                :file-list="fileList"
                                :show-file-list="false">
                            <el-button slot="trigger" size="small" type="primary">이미지선택
                            </el-button>
                            <el-button
                                    style="margin-left: 10px"
                                    size="small"
                                    type="success"
                                    @click="submitUpload()"
                            >이미지등록
                            </el-button>
                        </el-upload>
                    </div>
                    <div style="width:100%;padding-top: 10px;text-align: left" v-if="popup.type == managerConst.Popup.TYPE_IMAGE">
                        <el-image
                                style="width: 100px; height: 100px"
                                :src="popup.imageUrlTemp"
                                :preview-src-list="previewsrcList">
                        </el-image>
                        <p>이미지 클릭 원본을 확인할수 있습니다</p>
                    </div>
                    <div class="editor" style="width:100%;padding-top: 10px;text-align: left" v-if="popup.type == managerConst.Popup.TYPE_CONTENT">
                        <quill-editor ref="myQuillEditor"
                                      v-model="popup.content"
                                      :options="editorOption"
                                      @ready="onEditorReady($event)"
                                      @change="onEditorChange($event)"/>
                    </div>

                </div>

                <div slot="footer" class="dialog-footer">
                    <el-button @click="visiable = false">취소</el-button>
                    <el-button type="primary" @click="save">확인</el-button>
                </div>
            </el-dialog>

        </div>

    </div>

</template>

<script>

    import OnlineUserComp from "../../components/administrator/OnlineUserComp";
    import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
    import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";

    import managerConst from "../../common/administrator/managerConst";
    import {manager} from "../../common/administrator/managerMixin";
    import {Loading} from "element-ui";
    import {
        deleteCusTemp,
        getCusTempList,
        saveCusTemp,
        updateCusTemp
    } from "../../network/manager/customerTemplateRequest";
    import {delIp, getIpList, saveIp} from "../../network/manager/ipcontainerRequest";
    import {copyPopup, deletePopup, getPopupList, updatePopup} from "../../network/manager/popupRequest";
    import {uploadFile} from "../../network/manager/commonRequest";
    import {updateBoard} from "../../network/manager/boardRequest";
    import {updateGroup} from "../../network/manager/groupRequest";

    export default {
        name: "ManagerPopup",
        mixins: [manager],
        components: {ManagerTopbarComp, TodayStatisticComp, OnlineUserComp},
        data() {
            return {
                visiable: false,
                activeNames: 0,
                search: {},
                popup: {},
                popupList: [],
                actionUrl: '/upload_image_3',
                fileList: [],
                file: null,
                previewsrcList: [],
                editorOption: {
                    modules: {
                        toolbar: {
                            container: [
                                ['bold', 'italic', 'underline', 'strike'],        //加粗，斜体，下划线，删除线
                                ['blockquote', 'code-block'],         //引用，代码块
                                [{'header': 1}, {'header': 2}],               // 标题，键值对的形式；1、2表示字体大小
                                [{'list': 'ordered'}, {'list': 'bullet'}],          //列表
                                [{'script': 'sub'}, {'script': 'super'}],      // 上下标
                                [{'indent': '-1'}, {'indent': '+1'}],          // 缩进
                                [{'direction': 'rtl'}],                         // 文本方向
                                [{'size': ['small', false, 'large', 'huge']}],  // 字体大小
                                [{'header': [1, 2, 3, 4, 5, 6, false]}],         //几级标题
                                [{'color': []}, {'background': []}],          // 字体颜色，字体背景颜色
                                [{'font': []}],         //字体
                                [{'align': []}],        //对齐方式
                                ['clean'],        //清除字体样式
                            ]
                        }
                    },

                },

            }
        },
        methods: {
            edit(pop) {
                this.popup = pop
                this.previewsrcList = []
                this.previewsrcList.push(this.popup.imageUrlTemp)
                this.visiable = true
            },
            getPopupList() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                getPopupList(this.search, this.pageNum, this.pageSize).then(res => {
                    this.popupList = res.data.data
                    this.pageTotal = res.data.total
                    this.popupList.map(p => {
                        p.imageUrlTemp = p.imageUrl
                        p.imageUrl = null
                    })
                    loadingInstance.close()
                })
            },
            save() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                updatePopup(this.popup).then(res => {
                    loadingInstance.close()
                    if (res.data.success) {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: '업데이트 완료되였습니다'
                        });
                        this.getPopupList()
                        this.visiable = false
                    } else {
                        this.$message({
                            showClose: true,
                            duration: 3000,
                            message: res.data.msg,
                            type: 'error'
                        });
                    }
                })

            },
            copyPopup(id) {
                this.$confirm('해당 팝업을 복사 하시겠습니까?', '팝업 복사하기', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    copyPopup(id).then(res=>{
                        this.getPopupList()
                    })
                });

            },
            deletePopup(id) {
                this.$confirm('삭제 하시겠습니까?', '삭제', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    deletePopup(id).then(res => {
                        if (res.data.success) {
                            this.$message({
                                type: 'success',
                                duration: 1000,
                                message: '삭제 완료되였습니다'
                            });
                            this.getPopupList()
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 3000,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }


                    })

                });
            },
            handleSizeChange(val) {
                this.pageNum = 1;
                this.pageSize = val;
                this.getPopupList();
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.getPopupList();
            },
            httpRequest() {
                this.imgChange = true;
            },
            fileChanged(file, fileList) {
                this.file = file.raw;
            },
            beforeUpload(file) {

            },
            submitUpload() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                uploadFile(this.actionUrl, this.file).then(res => {

                    if (res.data.success) {
                        let uploadedFileName = res.data.data.uploadedFileName;
                        let fullpath = res.data.data.fullpath;
                        this.popup.imageUrl = uploadedFileName
                        updatePopup(this.popup).then(res => {
                            this.getPopupList()
                        })
                    }
                    loadingInstance.close()
                    this.$message({
                        type: 'success',
                        duration: 3000,
                        message: '이미지 등록이  완료되였습니다. [확인]버튼으로 업데이트를 진행하세요'
                    });
                })
            },
            onEditorReady(Quill) {

            },
            onEditorChange(editor) {
                this.popup.content = editor.html;
            },
        },
        created() {
            this.getPopupList()

        },
        watch: {}
    }
</script>

<style scoped>

</style>